<template>
  <section>
    <div class="recents">
      <h3 class="fw-bold mb-3">
        Articoli Recenti
      </h3>
      <article
        v-for="article in articles" :key="article.id"
        @click="goTo(article.id)"
      >
        <h5>
          {{ article.title }}
        </h5>
        <p class="c-gray fw-bold">
          {{ new Date(article.created_at).toLocaleDateString() }}
        </p>
        <hr class="" />
      </article>
    </div>
  </section>
</template>

<script>
import { paginateArticles } from "@/api/news.api";

export default {
  name: "RecentPosts",
  data() {
    return {
      articles: []
    }
  },
  methods: {
    async getArticles(page = 1) {
      const response = await paginateArticles(page);
      if (response.data) {
        this.articles = response.data;
      }
    },
    goTo(id) {
      window.location.href = `/news/${id}`;
    }
  },
  mounted() {
    this.getArticles();
  }
}
</script>

<style scoped>
  h5 {
    cursor: pointer;
  }

  h5:hover {
    color: #173759;
    font-weight: 600;
    transition: 0.5ms ease-in-out;
  }
</style>