<template>
  <section>
    <div class="container">
      <div class="row px-md-5">
        <div class="col-md-8">
          <TheArticle
            :image-name="article.image_url"
            :image-alt="article.title + ' copertina'"
            :title="article.title"
            author="John Doe"
            :date="new Date(article.created_at).toLocaleDateString()"
            :content="article.content"
          />
          <!-- In The Title aggiungere il content dell'articolo che al momento è fake e hard-coded -->
        </div>
        <div class="col-md-4 px-lg-3">
          <div class="search mb-5">
            <input
                type="text"
                placeholder="Cerca..."
                class="form-control"
            />
          </div>
          <RecentPosts />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import TheArticle from "@/components/news/TheArticle";
import RecentPosts from "@/components/news/RecentPosts";
import {showArticle} from "@/api/news.api";

export default {
  name: "Article",
  components: {
    RecentPosts,
    TheArticle
  },
  data() {
    return {
      article: {}
    }
  },
  methods: {
    async show(id) {
      const response = await showArticle(+id);
      this.article = response.data;
    }
  },
  mounted() {
    this.show(this.$route.params.id);
  }
}
</script>

<style scoped>
  .search input {
    box-shadow: none;
    border-color: #173759;
    border-radius: 0;
  }
</style>