<template>
  <article class="pb-5 ps-2 pe-4">
    <div class="image">
      <img
          class="obj-fit-cover w-100 h-100"
          :src="imageName"
          :alt="imageAlt"
      />
    </div>
    <div class="title py-4">
      <h1 class="fw-bold">
        {{ title }}
      </h1>
      <hr class="mb-0" />
    </div>
    <div class="info d-flex mb-4">
      <div class="author pe-3">
        {{ author }}
      </div>
      <div class="date pe-3">
        {{ date }}
      </div>
    </div>
    <div class="content mb-5">
      <div class="single-content" v-html="content"></div>
    </div>
  </article>
</template>

<script>
export default {
  name: "TheArticle",
  props: {
    imageName: String,
    imageAlt: String,
    title: String,
    content: String,
    author: String,
    date: String,
  }
}
</script>

<style scoped>
  .image {
    position: relative;
    width: 100%;
    height: 350px;
    object-position: center;
  }
</style>